import { CSSInterpolation } from '@emotion/css';
import { css } from '@emotion/react';

export const mobileMaxWidth = 1279;
export const desktopMinWidth = 1280;

export class HomepageMedia {
    static forMobile(args: CSSInterpolation): CSSInterpolation {
        return css`
            @media only screen and (max-width: ${mobileMaxWidth}px) {
                ${args}
            }
        `;
    }

    static forDesktop(args: CSSInterpolation): CSSInterpolation {
        return css`
            @media only screen and (min-width: ${desktopMinWidth}px) {
                ${args}
            }
        `;
    }
}
