import { LocaleMenu } from './components/LocaleMenu';
import { HomepageMedia } from './design/HomapageMedia';
import { PathName } from '@/modules/PathName';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import Media from 'libs/rtzr-design/Media';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { Paths, PathsForExternal } from 'src/modules/Paths';
import { logEvent } from 'src/modules/marketing/logEvent';

interface Props {
    highlightButton?: boolean;
}

export function GNB({ highlightButton, locale }: Props & PageProps): JSX.Element {
    const { t } = useTranslate({ locale });
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <Container>
            <WrapperForDesktop>
                <Link href={new PathName(Paths.Index).setLocale(locale).toString()} passHref>
                    <button>
                        <Image
                            src={require('./assets/icon/logo_with_margin.svg')}
                            width={132}
                            height={48}
                            layout="fixed"
                            alt="callabo logo"
                            priority
                        />
                    </button>
                </Link>
                <>
                    <LinkGroup>
                        <Link
                            className="block"
                            href={new PathName(Paths.Pricing).setLocale(locale).toString()}
                            passHref
                        >
                            <LinkItem
                                onMouseDown={() => logEvent('GNB', '클릭', '요금제')}
                                dangerouslySetInnerHTML={{ __html: t.home.GNB_PRICING }}
                            />
                        </Link>
                        <Link href={PathsForExternal.Blog} target="_blank">
                            <LinkItem
                                onMouseDown={() => logEvent('GNB', '클릭', '블로그')}
                                dangerouslySetInnerHTML={{ __html: t.common.BLOG }}
                            />
                        </Link>
                        {locale === 'ko' && (
                            <Link href={`${Paths.Docs}?from=gnb`} target="_blank">
                                <LinkItem
                                    onMouseDown={() => logEvent('GNB', '클릭', '이용 가이드')}
                                    dangerouslySetInnerHTML={{ __html: t.home.GNB_GUIDE }}
                                />
                            </Link>
                        )}
                        <Link href={Paths.Login} passHref>
                            <LinkItem
                                onMouseDown={() => logEvent('GNB', '클릭', '로그인')}
                                dangerouslySetInnerHTML={{ __html: t.home.GNB_LOGIN }}
                            />
                        </Link>
                        <Link href={Paths.Login} passHref>
                            <StartButton
                                onMouseDown={() => logEvent('GNB', '클릭', '트라이얼 신청')}
                                dangerouslySetInnerHTML={{
                                    __html: t.common.START_FOR_FREE_BUTTON,
                                }}
                                highlight={highlightButton}
                            />
                        </Link>
                    </LinkGroup>
                    <IconGroup>
                        <Link href={PathsForExternal.GooglePlayStore} target="_blank">
                            <Icon>
                                <Image
                                    src={require('./assets/icon/google_play_store.svg')}
                                    width={40}
                                    height={40}
                                    layout="fixed"
                                    alt="play_store"
                                />
                            </Icon>
                        </Link>
                        <Link href={PathsForExternal.AppleAppStore} target="_blank">
                            <Icon>
                                <Image
                                    src={require('./assets/icon/apple_app_store.svg')}
                                    width={40}
                                    height={40}
                                    layout="fixed"
                                    alt="app_store"
                                />
                            </Icon>
                        </Link>
                        <LocaleMenu locale={locale} />
                    </IconGroup>
                </>
            </WrapperForDesktop>
            <WrapperForMobile>
                <Link href={new PathName(Paths.Index).setLocale(locale).toString()} passHref>
                    <button style={{ height: 40 }}>
                        <Image
                            src={require('./assets/icon/logo_with_margin.svg')}
                            width={108}
                            height={40}
                            layout="fixed"
                            alt="callabo logo"
                        />
                    </button>
                </Link>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <LocaleMenu isMobile locale={locale} />
                    <Link
                        href={Paths.Login}
                        onMouseDown={() => logEvent('GNB', '클릭', '로그인')}
                        dangerouslySetInnerHTML={{ __html: t.home.GNB_LOGIN }}
                        style={{
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            color: '#001f71',
                        }}
                    ></Link>
                    <Icon onClick={() => setIsMenuOpen((prev) => !prev)}>
                        <Image
                            src={require('./assets/icon/gnb_menu_mobile.svg')}
                            width={32}
                            height={32}
                            layout="fixed"
                            alt="menu"
                        />
                    </Icon>
                </div>
                <GNBMenuForMobile isOpen={isMenuOpen}>
                    <AppDownload>
                        <div style={{ width: 78, height: 32 }} />
                        <span dangerouslySetInnerHTML={{ __html: t.home.GNB_APP_DOWNLOAD }} />
                        <Link href={PathsForExternal.GooglePlayStore} target="_blank">
                            <Image
                                src={require('./assets/icon/google_play_store.svg')}
                                width={32}
                                height={32}
                                layout="fixed"
                                alt="play_store"
                            />
                        </Link>
                        <Link href={PathsForExternal.AppleAppStore} passHref target="_blank">
                            <Image
                                src={require('./assets/icon/apple_app_store_light.svg')}
                                width={32}
                                height={32}
                                layout="fixed"
                                alt="app_store"
                            />
                        </Link>
                    </AppDownload>
                    <LinkGroup>
                        <Link
                            className="block w-full"
                            href={new PathName(Paths.Pricing).setLocale(locale).toString()}
                            passHref
                        >
                            <LinkItem>
                                <Image
                                    src={require('./assets/icon/gnb_mobile_pricing.svg')}
                                    width={40}
                                    height={40}
                                    layout="fixed"
                                    className="flex-shrink-0"
                                    alt="play_store"
                                />
                                <span dangerouslySetInnerHTML={{ __html: t.home.GNB_PRICING }} />
                            </LinkItem>
                        </Link>
                        <Link className="block w-full" href={PathsForExternal.Blog} target="_blank">
                            <LinkItem>
                                <Image
                                    src={require('./assets/icon/gnb_mobile_blog.svg')}
                                    width={40}
                                    height={40}
                                    layout="fixed"
                                    className="flex-shrink-0"
                                    alt="play_store"
                                />
                                <span dangerouslySetInnerHTML={{ __html: t.common.BLOG }} />
                            </LinkItem>
                        </Link>
                        {locale === 'ko' && (
                            <Link href={Paths.Docs} passHref className="block w-full">
                                <LinkItem>
                                    <Image
                                        src={require('./assets/icon/gnb_mobile_guid.svg')}
                                        width={40}
                                        height={40}
                                        className="flex-shrink-0"
                                        alt="play_store"
                                    />
                                    <span dangerouslySetInnerHTML={{ __html: t.home.GNB_GUIDE }} />
                                </LinkItem>
                            </Link>
                        )}
                    </LinkGroup>
                    <Link
                        href={Paths.Login}
                        passHref
                        className="block w-full mx-auto max-w-[280px]"
                    >
                        <StartButton
                            onMouseDown={() => logEvent('GNB', '클릭', '트라이얼 신청')}
                            dangerouslySetInnerHTML={{ __html: t.common.START_FOR_FREE_BUTTON }}
                            highlight={highlightButton}
                        />
                    </Link>
                </GNBMenuForMobile>
            </WrapperForMobile>
        </Container>
    );
}

const Container = styled.header`
    width: 100%;
    max-width: 1100px;
    position: sticky;
    top: 30px;
    z-index: 100;
    padding: 24px 32px;
    margin: 0 auto;
    border-radius: 14px;
    border: 1px solid #e6ecff;
    background: rgba(246, 249, 255, 0.95);
    backdrop-filter: blur(2px);
    white-space: nowrap;
    ${HomepageMedia.forMobile(`
        max-width: calc(100% - 32px);
        top: 16px;
        padding: 16px 20px;
    `)}
`;

const WrapperForDesktop = styled.nav`
    ${HomepageMedia.forMobile(`
        display: none;
    `)}
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const WrapperForMobile = styled.nav`
    display: flex;
    justify-content: space-between;
    ${HomepageMedia.forDesktop(`
        display: none;
    `)}
`;

const LinkGroup = styled.ul`
    display: flex;
    align-items: center;
    gap: 32px;
    list-style: none;
    ${HomepageMedia.forMobile(`
        flex-direction: column;
        gap: 4px;
        width: 100%;
    `)}
`;

const LinkItem = styled.li`
    color: #001f71;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    ${HomepageMedia.forMobile(`
        display: flex;
        align-items: center;
        gap: 14px;
        width: 100%;
        padding: 8px 12px;
        border-radius: 4px;
        background: #313745;
        font-size: 0.875rem;
        font-weight: 400;
        color: #fff;
    `)}
`;

const StartButton = styled.button<{ highlight: boolean }>`
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    padding: 12px 20px;
    box-sizing: border-box;
    color: #001f71;
    border: 1px solid #cbd9ff;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: #2e67fe;
        color: #fff;
        border: 1px solid #2e67fe;
        box-shadow: 0px 7px 16px 0px rgba(0, 31, 113, 0.15);
    }
    ${(props) => {
        if (props.highlight) {
            return css`
                background: #001f71;
                color: #fff;
                border: 1px solid #001f71;
            `;
        }
    }}
    ${HomepageMedia.forMobile(`
        width: 100%;
        max-width: 280px;
        text-align: center;
        font-size: 0.875rem;
        padding: 16px 32px;
        color: #fff;
        background: #2e67fe;
        border: none;
        border-radius: 4px;
    `)}
`;

const IconGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`;

const Icon = styled.button`
    width: 40px;
    height: 40px;
    ${HomepageMedia.forMobile(`
        width: 32px;
        height: 32px;
    `)}
`;

const GNBMenuForMobile = styled.div<{ isOpen: boolean }>`
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    gap: 32px;
    position: absolute;
    top: calc(100% + 24px);
    width: 100%;
    max-width: 400px;
    right: 0;
    padding: 32px 24px 40px 24px;
    border-radius: 28px;
    border: 1px solid #3a4663;
    background: #2b2f39;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
    ${Media.forMobile(`
        max-width: 100%;
    `)}
`;

const AppDownload = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 280px;
    span {
        color: #999;
        font-size: 0.875rem;
        font-weight: 400;
        margin-right: 20px;
    }
    a {
        width: 32px;
        height: 32px;
        &:nth-of-type(1) {
            margin-right: 14px;
        }
    }
`;
