import { HomepageMedia } from './HomapageMedia';
import styled from '@emotion/styled';

export class HomepageStyle {
    public static Container = styled.div`
        width: 100%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    public static ContentsLayout = styled.div`
        max-width: 912px;
        padding: 40px 0px 70px 0px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 60px;

        ${HomepageMedia.forMobile(`
            gap: 41px;
        `)}
    `;

    public static PageTitle = styled.h1`
        color: #222;
        text-align: center;

        font-size: 4rem;
        font-weight: 600;
        ${HomepageMedia.forMobile(`
            font-size: 1.5rem;
        `)}
    `;

    public static CardContainer = styled.div`
        display: flex;
        flex-flow: column wrap;
        width: 1280px;
        max-width: 1280px;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: stretch;
        gap: 26px;
        height: 2560px;

        ${HomepageMedia.forMobile(`
            width: 100%;
            max-width: 100%;
            align-items: center;
            align-content: center;
            justify-content: center;
            height: fit-content;
            gap: 12px;
            padding: 0 12px;
        `)}
    `;

    public static Card = styled.article<{ order?: number; height: number }>`
        box-sizing: border-box;
        border: 1px solid #e6ecff;
        background: #f6f9ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        order: ${({ order }) => order};
        height: ${({ height }) => height}px;
        ${HomepageMedia.forDesktop(`
            width: calc(50% - 13px);
            max-width: 627px;
            padding: 80px 0;
            border-radius: 56px;
        `)}
        ${HomepageMedia.forMobile(`
            height: fit-content;
            width: 100%;
            max-width: 400px;
            padding: 40px 0;
            border-radius: 28px;
            order: unset;
        `)}
    `;

    public static CardTitle = styled.h3`
        color: #222;
        text-align: center;

        font-size: 1.5rem;
        font-weight: 600;
        ${HomepageMedia.forMobile(`
            font-size: 1rem; 
        `)}
    `;

    public static CardDescription = styled.p`
        color: #222;
        text-align: center;

        font-size: 1.5rem;
        font-weight: 400;
        word-break: keep-all;
        margin-top: 32px;
        ${HomepageMedia.forMobile(`
            font-size: 0.875rem; 
            margin-top: 16px;
        `)}
    `;

    public static SectionTitle = styled.h2`
        color: #222;
        text-align: center;

        font-size: 3rem;
        font-weight: 600;
        ${HomepageMedia.forMobile(`
            font-size: 1.5rem;
        `)}
    `;

    public static SectionDescription = styled.div`
        font-size: 1.25rem;
        line-height: 140%;
        font-weight: 400;

        text-align: center;
        color: #222;
        ${HomepageMedia.forMobile(`
            font-size: 0.875rem;
            color: #999999;
        `)}
    `;

    static DesktopOnly = styled.div`
        ${HomepageMedia.forMobile(`
            display: none;
        `)}
    `;

    static MobileOnly = styled.div`
        ${HomepageMedia.forDesktop(`
            display: none;
        `)}
    `;
}
