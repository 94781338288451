import { HomepageMedia } from './design/HomapageMedia';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import { Paths, PathsForExternal } from 'src/modules/Paths';
import { logEvent } from 'src/modules/marketing/logEvent';

export function Footer({ locale }: { locale: Locales }): JSX.Element {
    const { t } = useTranslate({ locale });
    const Inner = locale === 'ja' ? MainText : Fragment;

    return (
        <Container>
            {locale === 'ja' && (
                <Wrapper style={{ marginRight: 'auto' }}>
                    <Logo>
                        <Image
                            src={require('./assets/icon/footer_nihonium.svg')}
                            layout="fixed"
                            alt="nihonium logo"
                        />
                    </Logo>
                    <MainText>{t.common.FOOTER_PAYMENT_COMPANY}</MainText>
                    <CompanyInfo>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t.common.FOOTER_PAYMENT_COMPANY_ADDRESS,
                            }}
                        />
                        <br />
                        <br />
                        <span>© Nihonium Inc.</span>
                    </CompanyInfo>
                </Wrapper>
            )}
            <Wrapper>
                <Logo>
                    <Icon>
                        <Image
                            src={require('./assets/icon/footer_logo.svg')}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            alt="logo"
                        />
                    </Icon>
                    <Image
                        src={'/callabo/logo.svg'}
                        width={101}
                        height={22}
                        layout="fixed"
                        className="h-[22px]"
                        alt="logo text"
                    />
                </Logo>
                <MainText>
                    <Link
                        href={Paths.TermService}
                        target="_blank"
                        onMouseDown={() => logEvent('FOOTER', '클릭', '서비스이용약관')}
                        dangerouslySetInnerHTML={{ __html: t.common.TERM_SERVICE }}
                    ></Link>
                    <Divider />
                    <Link
                        href={Paths.TermPrivacy}
                        target="_blank"
                        onMouseDown={() => logEvent('FOOTER', '클릭', '개인정보처리방침')}
                        dangerouslySetInnerHTML={{ __html: t.common.TERM_PRIVACY }}
                    ></Link>
                    <Divider />
                    {locale === 'ja' && (
                        <>
                            <Link href={Paths.NoticeForStripe} target="_blank">
                                {'特定商取引法に基づく表記'}
                            </Link>
                        </>
                    )}
                    <Inner>
                        <Link
                            href={PathsForExternal.Blog}
                            target="_blank"
                            onMouseDown={() => logEvent('FOOTER', '클릭', '블로그')}
                            dangerouslySetInnerHTML={{ __html: t.common.BLOG }}
                        ></Link>
                        <Divider />
                        <Link
                            href={PathsForExternal.LinkedIn}
                            target="_blank"
                            onMouseDown={() => logEvent('FOOTER', '클릭', '링크드인')}
                            dangerouslySetInnerHTML={{ __html: t.home.FOOTER_LINKED_IN }}
                        ></Link>
                        <Divider />
                        {locale === 'ko' && (
                            <>
                                <Link
                                    href={PathsForExternal.OpenChat}
                                    target="_blank"
                                    onMouseDown={() => logEvent('FOOTER', '클릭', '오픈채팅')}
                                    dangerouslySetInnerHTML={{
                                        __html: t.home.FOOTER_OPEN_CHAT,
                                    }}
                                ></Link>
                                <Divider />
                            </>
                        )}
                        <Link
                            href={PathsForExternal.RTZR}
                            target="_blank"
                            onMouseDown={() => logEvent('FOOTER', '클릭', '리턴제로')}
                            dangerouslySetInnerHTML={{ __html: t.home.FOOTER_RTZR }}
                        ></Link>
                    </Inner>
                </MainText>
                <CompanyInfo>
                    <p dangerouslySetInnerHTML={{ __html: t.common.FOOTER_COMPANY_INFO }} />
                    <br />
                    <br />
                    <span>© ReturnZero Inc.</span>
                </CompanyInfo>
            </Wrapper>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 88px 40px 60px 40px;
    margin: 0 auto;
    border-top: 1px solid #d9d9d9;

    ${HomepageMedia.forMobile(`
        flex-direction: column-reverse;
        padding: 0 32px 80px 32px;
    `)}
`;

const Wrapper = styled.div`
    max-width: 50%;
    display: flex;
    flex-direction: column;

    ${HomepageMedia.forMobile(`
        max-width: 100%;
        gap: 24px;
        padding: 32px 0;
        &:nth-of-type(2) {
            border-bottom: 1px solid #d9d9d9;
        }
    `)}
`;

const Logo = styled.button`
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 24px;
    ${HomepageMedia.forMobile(`
        height: 24px;
        margin-bottom: 0;
    `)}
`;

const Icon = styled.div`
    width: 28px;
    height: 28px;
    ${HomepageMedia.forMobile(`
        width: 24px;
        height: 24px;
    `)}
`;

const Divider = styled.div`
    min-width: 1px;
    width: 1px;
    height: 16px;
    background: #626262;
    ${HomepageMedia.forMobile(`
        display: none;
    `)}
`;

const MainText = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    gap: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    color: #626262;
    max-width: 100%;
    word-wrap: break-word;
    word-break: keep-all;

    ${HomepageMedia.forMobile(`
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        font-size: 0.875rem;
    `)}
`;

const CompanyInfo = styled.div`
    font-weight: 400;
    font-size: 0.875rem;
    color: #9f9f9f;
    margin-top: 32px;
    word-break: keep-all;
    ${HomepageMedia.forMobile(`
        font-size: 0.75rem;
        margin: 0;
    `)}
`;
