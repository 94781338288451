import { HomepageMedia } from '../design/HomapageMedia';
import styled from '@emotion/styled';
import { Locales } from 'callabo-locale/src/hooks/useTranslate';
import { PageProps } from 'libs/callabo-state/types/PageProps';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface Props {
    isMobile?: boolean;
}

export const LocaleMenu = ({ isMobile, locale: savedLocale }: Props & PageProps): JSX.Element => {
    const [isLocaleOpen, setIsLocaleOpen] = useState(false);
    const router = useRouter();
    const params = new URLSearchParams(router.query as Record<string, string>);
    params.delete('locale');

    const handleChangeLanguage = async (locale: Locales) => {
        if (savedLocale === locale) return;
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30); // 쿠키 만료일: 30일
        const cookieString = `locale=${locale}; expires=${expiryDate.toUTCString()}; path=/`;
        document.cookie = cookieString;
    };

    return (
        <Container>
            <Button onClick={() => setIsLocaleOpen((prev) => !prev)}>
                <Image src={require('../assets/icon/locale.svg')} layout="fixed" alt="lang" />
                {!isMobile && (
                    <Image
                        src={require('../assets/icon/arrow_down.svg')}
                        layout="fixed"
                        alt="arrow"
                        style={{
                            transform: isLocaleOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                        }}
                    />
                )}
            </Button>
            <Menu visible={isLocaleOpen}>
                <a
                    href={
                        router.pathname.replace('[locale]', 'ko') +
                        (params.toString() ? `?${params.toString()}` : '')
                    }
                >
                    <li onClick={() => handleChangeLanguage('ko')}>한국어</li>
                </a>
                <a
                    href={
                        router.pathname.replace('[locale]', 'en') +
                        (params.toString() ? `?${params.toString()}` : '')
                    }
                >
                    <li onClick={() => handleChangeLanguage('en')}>English</li>
                </a>
                <a
                    href={
                        router.pathname.replace('[locale]', 'ja') +
                        (params.toString() ? `?${params.toString()}` : '')
                    }
                >
                    <li onClick={() => handleChangeLanguage('ja')}>日本語</li>
                </a>
            </Menu>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const Menu = styled.ul<{ visible: boolean }>`
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    list-style: none;
    min-width: 120px;
    background: #2b2f39;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.15));
    padding: 5px 4px;
    z-index: 100;

    li {
        color: #fff;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
        padding: 14px 0;
        border-radius: 4px;

        &:not(:nth-of-type(1)) {
            margin-top: 4px;
        }
    }

    ${HomepageMedia.forMobile(`
        top: calc(100% + 14px);
    `)}
`;
